@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Vazirmatn", sans-serif;
}

html {
  scroll-behavior: smooth;
}
